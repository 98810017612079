<template>
  <div class="amount" :class="{yeb:isYeb}">
    <RechargeCenter :isShow="isShowRecharge" />
    <div class="coin-ico" :class="{yeb:isYeb}"></div>
    <div class="amount-v" :class="{yeb:isYeb}">
      {{ getShowValue() }}
    </div>
    <div class="refresh-ico" v-if="!isYeb"
      :class="{refreshing:userInfoRefreshing}"
      @click="refresh_amount">
    </div>
    <div @click="rechargeClick" class="add-p-ico"></div>
  </div>
</template>
<script>
import Tool from '../../common/Tool.js'
import Request from '../../common/Request.js'
import UserInfo from '../../common/UserInfo.js'
import RechargeCenter from '../recharge/RechargeCenter.vue'
import { mapGetters, mapMutations } from 'vuex'

export default{
  props:{
    isYeb:Number,
    amount:Number|String,
    showRecharge:Number,
    isRefresAmount:Number,
  },
  data(){
    return {
      userCoin:'',
      userInfoRefreshing:false,
      isShowRecharge:0,
      yebDetails:{},
      getUserInfoTime:0,
    }
  },
  mounted(){
    this.getYebData();
    this.soketSub();
  },
  methods:{
    ...mapMutations(['popMsg']),
    soketSub(){
      const userInfo = Tool.getLocalUserinfo();
      if(userInfo && userInfo.hashid && this.$socket && this.$socket.private){
        this.$socket.private('user.'+userInfo.hashid).listen('.money', (e)=>{
          Tool.removeLocalYeb()
          Tool.removeLocalUserInfo()
          this.setUserCoin(e.money)
          this.yebDetails.balance = e.yeb_balance
        })
      }
    },
    getShowValue(){
      if(this.isYeb){
        if(this.yebDetails.balance){
          return (1*this.yebDetails.balance).toFixed(2)
        }else{
          return '0.00'
        }
      }else{
        return this.userCoin;
      }
    },
    rechargeClick(){
      UserInfo.getUserInfoData(data => {
        if(data.is_tourist){
          this.$emit('touristShow')
        }else{
          if(this.isYeb){
            this.$emit('yebClick')
          }else{
            this.isShowRecharge = Math.random();
          }
        }
      })
    },
    transferOutAllAfter(){
      clearTimeout(this.getUserInfoTime)
      this.getUserInfoTime = setTimeout(() => {
        UserInfo.getUserInfoData((userInfo) => {
          if(userInfo){
            this.setUserCoin(userInfo.coin)
          }
          this.userInfoRefreshing = false;
        },'update')
      }, 1000);
    },
    refresh_amount(){
      if(this.isYeb)return;
      UserInfo.getUserInfoData(data => {
        if(data.is_tourist){
          this.$emit('touristShow')
        }else{
          this.userInfoRefreshing = true;
          Request.post('/platform/transfer_out_all').then(() => {
            this.transferOutAllAfter();
          }).catch(() => {
            this.transferOutAllAfter();
          })
        }
      })
    },
    getYebData(isRefresh,callback){
      let localYeb = Tool.getLocalYeb();
      if(localYeb && !isRefresh){
        this.yebDetails = localYeb;
        if(callback)callback();
        return;
      }
      Tool.removeLocalYeb();
      Request.post('/yeb/index').then(res => {
        this.yebDetails = res.data.data;
        Tool.setLocalYeb(res.data.data);
        if(callback)callback();
      }).catch(() => {
        if(callback)callback();
      });
    },
    //callback刷新余额时传递
    getUserInfo(callback){
      let token = Tool.getApiToken();
      if(!token){
        Request.userOutted(this)
        if(callback) callback();
        return;
      }
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          this.setUserCoin(res.data.data.coin)
          Tool.setLocalUserinfo(res.data.data);
          if(callback) callback();
        }
      });
    },
    setUserCoin(v){
      this.userCoin = (v*1).toFixed(2);
    }
  },
  watch:{
    isRefresAmount(n){
      if(n){
        this.refresh_amount();
      }
    },
    isUpdateAmountAndYeb(){
      if(this.isYeb){
        this.getYebData();
      }else{
        if(!this.userInfoRefreshing){
          this.userInfoRefreshing = true;
          this.getUserInfo(() => {
            this.userInfoRefreshing = false;
          });
        }
      }
    },
    isPopRecharge(n){
      if(!this.isYeb){
        this.isShowRecharge = Math.random();
      }
    },
    amount(n){
      this.setUserCoin(n)
    },
    showRecharge(n){
      if(n){
        if(!this.isYeb){
          this.isShowRecharge = Math.random();
        }
      }
    },
    isUpdateUserCoin(n){
      if(n){
        if(!this.isYeb){
          Tool.removeLocalUserInfo();
          this.userInfoRefreshing = true;
          this.getUserInfo(() => {
            this.userInfoRefreshing = false;
          });
        }
      }
    }
  },
  computed:{
    ...mapGetters({
      isPopRecharge:'getIsPopRecharge',
      isUpdateAmountAndYeb:'getUpdateAmountAndYeb',
      isUpdateUserCoin:'getIsUpdateUserCoin'
    }),
  },
  components:{
    RechargeCenter
  }
}
</script>

<style scoped lang="stylus">
@keyframes loading_btn {
  from{
    transform rotateZ(1deg)
  }
  to{
    transform rotateZ(360deg)
  }
}
.amount
  width 1.4rem
  height .5rem
  margin-left .12rem
  display flex
  background-image url('../../assets/home/top/amount_bg.png')
  background-size 100% 87%
  background-repeat no-repeat
  background-position-y .03rem
  border-top 1px solid transparent
  &.yeb
    width 1.12rem
  .coin-ico
    width .26rem
    height .29rem
    margin-top .1rem;
    background-image url('../../assets/home/top/species_ico.png')
    background-size cover
    &.yeb
      width .31rem
      height .38rem
      margin-top .05rem
      background-image url('../../assets/home/top/receive_coins_ico.png')
      background-size 100% 100%
  .amount-v
    width .6rem
    height .2rem
    text-align center
    overflow hidden
    font-size .14rem
    margin-top .16rem
    color #F0F0B7
    &.yeb
      width .56rem
  .refresh-ico  
    width .23rem
    height .21rem
    margin-top .13rem
    margin-right .04rem
    background-image url('../../assets/home/top/amount_refresh_icon.png')
    background-size cover
    &.refreshing
      animation loading_btn 1s infinite linear
  .add-p-ico
    width .26rem
    height .26rem
    margin-top .11rem
    background-image url('../../assets/home/top/top_add_ico.png')
    background-size cover
    transition transform .2s
    &:active
      transform scale(.9)
</style>
