export default{
  storeKeys:{
    gradInfo:'gradInfo',
    deviceId:'device-id',
  },
  walletId:[116,122,133,135],
  loginInvalid:'登录失效，请重新登录!',
  getQuery(key){
    let result = '';
    let paramStr = location.href.split('?')[1];
    if(paramStr){
      let pArray = paramStr.split('&');
      if(pArray && pArray.length){
        pArray.forEach(kv => {
          if(kv.split('=')[0] && kv.split('=')[1]){
            if(kv.split('=')[0] == key){
              result = kv.split('=')[1];
              result = result.split('#')[0]
            }
          }
        })
      }
    }
    return result;
  },
  getGuid(){
    // 生成四个随机的十六进制数字。  
    function S4() {  
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);  
    }
    // 通过连接随机十六进制生成伪 GUID。
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());  
  },
  getMobileBrand(){
    let uaStr = navigator.userAgent.toLowerCase();
    let output = this.judgeBrand(uaStr);
    return output;
  },
  judgeBrand(sUserAgent){
    let dict = [
      {iphone:/iphone/i},
      {huawei:/huawei/i},
      {honor:/honor/i},
      {oppo:/oppo/i},
      {pacm00:/pacm00/i},
      {vivo:/vivo/i},
      {'mi ':/mi\s/i},
      {'mix ':/mix\s/i},
      {redmi:/redmi/i},
      {'sm-':/sm-/i},
      {'mb2345browser':/mb2345browser/i},
    ]
    let cBrand = '';
    for(let i = 0;i < dict.length;i++){
      let brand = Object.keys(dict[i])[0];
      let zzBds = dict[i][brand];
      if(sUserAgent.match(zzBds) == brand){
        cBrand = brand;
        break;
      }
    }
    return cBrand;
  },

  getDeviceId(){
    let storageKey = this.storeKeys.deviceId;
    let deviceId = localStorage.getItem(storageKey);
    if(!deviceId){
      deviceId = this.getGuid();
      localStorage.setItem(storageKey,deviceId);
    }
    return deviceId;
  },
  setLoginUserName(username){
    localStorage.setItem('loginusername',username)
  },
  getLoginUserName(){
    return localStorage.getItem('loginusername')
  },
  setApiToken(token){
    localStorage.setItem('api_token',token)
  },
  getApiToken(){
    return localStorage.getItem('api_token')
  },
  clearApiToken(){
    localStorage.removeItem('api_token')
  },
  remToPx(rem){
    let m = innerWidth > innerHeight?innerWidth:innerHeight;
    let htmlFsize = m * 100 / 770;
    return rem * htmlFsize;
  },
  copy(inputEle){
    //只能在安全环境(localhost或者https)中使用
    if(navigator.clipboard){
      inputEle.select();
      inputEle.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(inputEle.value);
    }else{
      const range = document.createRange();
      range.selectNode(inputEle);
      const selection = window.getSelection();
      if (selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      if(selection.removeRange){
        selection.removeRange(range);
      }
    }
  },
  async paste(){
    navigator.permissions.query({
      name:'clipboard-read'
    }).then(pStatus => {
      console.log(pStatus.state)
      pStatus.onchange = () => {
        console.log(pStatus.state)
      }
    })
    
    let paste = undefined;
    if(navigator.clipboard){
      paste = await navigator.clipboard.readText();
    }else{ 
      if(window.clipboardData && window.clipboardData.getData){//IE
        paste = window.clipboardData.getData('Text');
      } else if(event.clipboardData && event.clipboardData.getData){//其他浏览器
        paste = event.clipboardData.getData('text/plain');
      }
    }
    return paste;
  },
  removeLocalUserInfo(){
    localStorage.removeItem('user-info')
  },
  getLocalUserinfo(){
    let userInfo = localStorage.getItem('user-info');
    if(userInfo){
      return JSON.parse(userInfo)
    }
    return null
  },
  setLocalUserinfo(info){
    localStorage.setItem('user-info',JSON.stringify(info))
  },
  setBankList(data){
    localStorage.setItem('banklist',JSON.stringify(data));
  },
  getBankList(){
    let bankStr = localStorage.getItem('banklist');
    if(!bankStr) {
      return null;
    }
    let bankJson = JSON.parse(bankStr);
    let now = new Date();
    let oData = new Date(bankJson.date);
    if(now - oData < 24 * 60 * 60 * 1000){
      return bankJson.bankArray;
    }
    return null
  },
  // 不可使用 localStorage.clear(); ，因为要尽量保证device_id不变
  quit(){
    Object.keys(localStorage).forEach(key => {
      let liwai = [
        'api-host','device-id','loginusername','agent_code',
        'bg-music-flag'] //,'auto-tan-chuant'
      if(!liwai.includes(key)){
        localStorage.removeItem(key)
      }
    })
  },
  remveGradeInfo(){
    localStorage.removeItem(this.storeKeys.gradInfo)
  },
  setGradeInfo(obj){
    let cache = {
      date:new Date(),
      data:obj
    }
    localStorage.setItem(this.storeKeys.gradInfo,JSON.stringify(cache))
  },
  getGradeInfo(){
    let cacheStr = localStorage.getItem(this.storeKeys.gradInfo);
    if(!cacheStr) return null
    let cachObj = JSON.parse(cacheStr);
    let now = new Date();
    if(now - new Date(cachObj.date) < 1000 * 60 * 60 * 5){
      return cachObj.data;
    }
    return null;
  },
  setZijinTypes(obj){
    localStorage.setItem('zijin-type',JSON.stringify(obj));
  },
  getZijinTypes(){
    let cash = localStorage.getItem('zijin-type');
    return JSON.parse(cash);
  },
  setUserBetFilter(obj){
    localStorage.setItem('user-bet-filter',JSON.stringify(obj));
  },
  getUserBetFilter(){
    let cachStr = localStorage.getItem('user-bet-filter');
    if(!cachStr){
      return null
    }
    return JSON.parse(cachStr);
  },
  //type:1 电子游艺， 3 棋牌
  getDzyyMenu(type){
    let key = '';
    switch(1*type){
      case 1:
        key = 'dzyy-menu-data';
        break;
      case 3:
        key = 'qipai-m-data';
        break;
    }
    if(!key) return null;
    let cache = localStorage.getItem(key);
    if(cache && cache != 'null'){
      return JSON.parse(cache);
    }
    return null
  },
  setDzyyMenu(obj,type){
    let key = '';
    switch(1*type){
      case 1:
        key = 'dzyy-menu-data';
        break;
      case 3:
        key = 'qipai-m-data';
        break;
    }
    if(!key) return null;

    localStorage.setItem(key,JSON.stringify(obj));
  },
  getPayWays(){
    let cacheStr = localStorage.getItem('pay_ways');
    if(!cacheStr) return null
    let wayList = JSON.parse(cacheStr);
    if(wayList && wayList.length){
      return wayList;
    }
  },
  setPayWays(obj){
    localStorage.setItem('pay_ways',JSON.stringify(obj));
  },
  getCusLink(){
    return localStorage.getItem('customer-link');
  },
  setCusLink(cusLink){
    localStorage.setItem('customer-link',cusLink);
  },
  getFaqList(){
    let list = localStorage.getItem('faq-list');
    if(list){
      let array =  JSON.parse(list);
      if(array && array.length){
        return array;
      }
    }
  },
  setFaqList(obj){
    if(obj && obj.length){
      localStorage.setItem('faq-list',JSON.stringify(obj));
    }
  },
  setActivities(obj){
    localStorage.setItem('activity-mission',JSON.stringify(obj));
  },
  getActivities (){
    let ac = localStorage.getItem('activity-mission');
    if(ac){
      return JSON.parse(ac)
    }
  },
  getLuckyList(){
    let local = localStorage.getItem('act-lucky-list');
    if(local){
      return JSON.parse(local)
    }
  },
  setLuckyList(obj){
    localStorage.setItem('act-lucky-list',JSON.stringify(obj));
  },
  setActPrizeList(id,data){
    localStorage.setItem('act-prize-list'+id,JSON.stringify(data));
  },
  getActPrizeList(id){
    let str = localStorage.getItem('act-prize-list'+id);
    if(str){
      return JSON.parse(str);
    }
  },
  getStatusSign(){
    let str = localStorage.getItem('status-sign-list');
    if(str){
      return JSON.parse(str);
    }
  },
  setStatusSign(obj){
    localStorage.setItem('status-sign-list',JSON.stringify(obj))
  },
  getMarqueeList(){
    let str = localStorage.getItem('marquee-list');
    if(str){
      return JSON.parse(str);
    }
  },
  setMarqueeList(obj){
    if(obj && obj.length){
      localStorage.setItem('marquee-list',JSON.stringify(obj))
    }
  },
  getAgentYieldInfo(){
    let str = localStorage.getItem('agent-yield-info');
    if(str){
      return JSON.parse(str);
    }
  },
  setAgentYieldInfo(obj){
    if(obj && obj.url){
      localStorage.setItem('agent-yield-info',JSON.stringify(obj))
    }
  },
  setBonusInfo(obj){
    localStorage.setItem('bonus-list-info',JSON.stringify(obj))
  },
  getBonusInfo(){
    let data = localStorage.getItem('bonus-list-info');
    if(data){
      return JSON.parse(data);
    }
  },
  setAutoTanchuang(){
    let now = new Date();
    let p = {
      flag:1,
      time:now
    };
    localStorage.setItem('auto-tan-chuant',JSON.stringify(p));
  },
  getAutoTanchuang(){
    let data = localStorage.getItem('auto-tan-chuant');
    if(data){
      data = JSON.parse(data);
      let sDt = new Date(data.time);
      let nTime = new Date();
      if(sDt.getDate() == nTime.getDate()){
        return data.flag;
      }
    }
  },
  openWechat() {
    window.open("weixin://weixin.qq.com/r/6C_chAXEflgBrUHn93r6");
  },
  getGameUrl(){
    return localStorage.getItem('gameurl');
  },
  isComputer(){
    const system = {}; 
    const p = navigator.platform;      
    system.win = p.indexOf("Win") == 0; 
    system.mac = 0//p.indexOf("Mac") == 0; 
    system.x11 = (p == "X11") || (p.indexOf("Linux") == 0);
    if(system.win||system.mac||system.xll){
      return true;
    }
    return false;
  },
  setGameUrl(gameurl){
    localStorage.setItem('gameurl',gameurl);
  },
  getPromLocalInfo(){
    let promLocalInfo = localStorage.getItem('prom-local-info');
    if(promLocalInfo){
      return JSON.parse(promLocalInfo);
    }
  },
  setPromLocalInfo(obj){
    localStorage.setItem('prom-local-info',JSON.stringify(obj))
  },
  setMusicSwitch(flag){
    localStorage.setItem('bg-music-flag',flag);
  },
  getMusicSwitch(){
    return localStorage.getItem('bg-music-flag');
  },
  getGameListLocal(cacheKey){
    const local = localStorage.getItem(cacheKey);
    if(local){
      return JSON.parse(local);
    }
  },
  setGameListLocal(cacheKey,obj){
    localStorage.setItem(cacheKey,JSON.stringify(obj));
  },
  base64ToBlob(code) {
    let parts = code.split(';base64,')
    let contentType = parts[0].split(':')[1]
    let raw = window.atob(parts[1]) // 解码base64得到二进制字符串
    let rawLength = raw.length
    let uInt8Array = new Uint8Array(rawLength) // 创建8位无符号整数值的类型化数组
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i) // 数组接收二进制字符串
    }
    return new Blob([uInt8Array], { type: contentType })
  },
  async blobToBase64(blob) {
    let buffer = await blob.arrayBuffer()
    let bytes = new Uint8Array(buffer);

    let binary = ''
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return 'data:image/webp;base64,' + window.btoa(binary)
  },
  getLocalYeb(){
    let localYebInfo = localStorage.getItem('local-yeb-info');
    if(localYebInfo){
      return JSON.parse(localYebInfo);
    }
  },
  setLocalYeb(obj){
    localStorage.setItem('local-yeb-info',JSON.stringify(obj));
  },
  removeLocalYeb(){
    localStorage.removeItem('local-yeb-info');
  },
  getBankRechargeList(){
    let str = localStorage.getItem('bank-recharge-list');
    if(str){
      return JSON.parse(str);
    }
  },
  setBankRechargeList(list){
    localStorage.setItem('bank-recharge-list',JSON.stringify(list));
  },
  getKuaijieRecharge(){
    let str = localStorage.getItem('vip-recharge-list');
    if(str){
      return JSON.parse(str);
    }
  },
  setKuaijieRecharge(list){
    localStorage.setItem('vip-recharge-list',JSON.stringify(list));
  },
  getCompanyRecharge(){
    let str = localStorage.getItem('company-recharge-list');
    if(str){
      return JSON.parse(str);
    }
  },
  setCompanyRecharge(list){
    localStorage.setItem('company-recharge-list',JSON.stringify(list));
  },
  getActivitiesList(){
    let str = localStorage.getItem('hot-activities-list');
    if(str){
      return JSON.parse(str);
    }
  },
  setActivitiesList(list){
    localStorage.setItem('hot-activities-list',JSON.stringify(list));
  },
  setActivityApply(value){
    localStorage.setItem('activity-apply-c',value);
  },
  getActivityApply(){
    return localStorage.getItem('activity-apply-c');
  },
  getPoiByParent(element){
    let actualLeft = element.offsetLeft,
      actualTop = element.offsetTop,
      current = element.offsetParent;
    while(current){
      actualLeft += current.offsetLeft;
      actualTop += current.offsetTop;
      current = current.offsetParent;
    }
    return{
      left:actualLeft,
      top:actualTop
    }
  },
  getPosition(element){
    const dc = document,
      rec = element.getBoundingClientRect();
    let _x = rec.left,
        _y = rec.top;
    _x += dc.documentElement.scrollLeft || dc.body.scrollLeft;
    _y += dc.documentElement.scrollTop || dc.body.scrollTop;

    return{
      left:_x,
      top:_y
    }
  },
  getHostList(){
    let str = localStorage.getItem('host-local-data-list');
    if(str){
      return JSON.parse(str);
    }
  },
  setHostList(list){
    if(list && list.length){
      localStorage.setItem('host-local-data-list',JSON.stringify(list));
    }
  },
  noNeedShupingRotate(){
    let outPaths = ['/play','/customer'];
    let noNeedShuping = false;
    outPaths.forEach(o => {
      if(location.href.indexOf(o) > -1){
        noNeedShuping = true;
      }
    })
    return noNeedShuping;
  },
  getMainMenuData(){
    let strData = localStorage.getItem('main-menu-show-data');
    if(strData){
      return JSON.parse(strData);
    }
  },
  setMainMenuData(data){
    if(!data) return;
    localStorage.setItem('main-menu-show-data',JSON.stringify(data));
  },
  setSignupDataList(data){
    localStorage.setItem('signup-data-list',JSON.stringify(data));
  },
  getSignupDataList(){
    let data = localStorage.getItem('signup-data-list');
    return data ? JSON.parse(data) : null;
  },
  urlProtocalHandle(url){
    if(!url){
      return ''
    }
    if(url.indexOf('http://') > -1){
      return url.replace('http://','//')
    }else{
      return url.replace('https://','//')
    }
  }
}
