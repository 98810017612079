<template>
  <div class="rank-privilege">
    <img class="decorate" src="../../assets/geren/ctitle-djtq.png" />
    <div class="flex">
      <div class="vip-l-wrap p-relative">
        <img v-if="gradeInfo.grade<1" class="vip-ico" 
          src="../../assets/geren/vip-ico0.png" alt="">
        <img v-else class="vip-ico" 
          src="../../assets/geren/vip-ico1.png" alt="">
        <div class="vip-l-value">
          {{gradeInfo.grade ? 'VIP'+gradeInfo.grade : ''}}
        </div>
      </div>
      <div class="flex vip-bar">
        <img v-if="gradeInfo.grade == 0"
          class="normal-vip" src="../../assets/geren/normal-vip.png" />
        <div v-else class="vip-ico-wrapper">
          <img :src="getNextLevelIco(1)" />
        </div>
        <div class="process-bar-wrap flex">
          <div class="process-bar flex">
            <div class="pro-inner" :style="{width:processExp}">
              <div class="inner-bar"></div>
            </div>
            <div class="p-vip-value">
              {{gradeInfo.exp}}/{{gradeInfo.nextExp}}
            </div>
          </div>
          <div style="color:#AC9D88">
            还差<span style="color:#2A8925;">{{ subExp }}</span>
            打码即可升级到<span style="color:#F7F296;">
              VIP{{ gradeInfo.nextGrade }}
            </span>
          </div>
        </div>
        <div class="vip-ico-wrapper">
          <img :src="getNextLevelIco()" />
        </div>
      </div>
    </div>
    <div class="vip-quanyi-wrapper">
      <div class="flex vip-q-t-b-w">
        <img @click="lsArrClick(1)" class="lr-ico" 
          src="../../assets/geren/vip-l-left.png" />
        <div class="vip-ceil-wrapper flex" ref="vipCeilWrapper">
          <div class="vip-level-btn flex" v-for="(item,i) of gradeInfo.info"
            @click="vipLevelBtnClick(i)" :class="{selected:i == wrapperLNum}">
            VIP{{item.grade}}
          </div>
        </div>
        <img @click="lsArrClick(-1)" class="lr-ico" 
          src="../../assets/geren/vip-l-right.png" />
      </div>
      <div class="vip-scribe-wrap flex">
        尊敬的<span>VIP{{getSelectedLevel()}}</span>会员，您可以享受以下权益
      </div>
      <div class="q-o-wrapper p-relative" 
        @touchstart="quanyiListWtouchstart"
        @touchmove='quanyiListWtouch'
        @touchend="quanyiListWtouchend">
        <img class="ls-ico l" @click='lsArrClick(1)' v-show="wrapperLNum > 0"
          src="../../assets/geren/v-qy-s-left.png" />
        <div class="wrapper-ov-hidden">
          <div class="flex wrapper-i-list" :class="{effect:isWrapEffect}"
            :style="{transform:`translateX(${wrapperLeft}px)`}">
            <div class="quanyi-i-list flex" v-for="wrapItem of gradeInfo.info">
              <div class="quanyi-item" v-for="(item,iVl) of getLijinEduShow(wrapItem)">
                <div class="quanyi-top-title">
                  {{ item.title }}
                </div>
                <img class="qy-de-coin1" v-if="iVl==0" 
                  src="../../assets/geren/quanyi-de-coin1.png" />
                <img class="qy-de-coin1" v-if="iVl==1"
                  src="../../assets/geren/quanyi-de-coin2.png" />
                <img class="qy-de-coin1" v-if="iVl==2"
                  src="../../assets/geren/quanyi-de-coin3.png" />
                <img class="qy-de-coin1" v-if="iVl==3" 
                  src="../../assets/geren/quanyi-de-coin4.png" />
                <div class="jine" v-if="iVl != 3">{{item.jine}}元</div>
                <div class="jine" v-else>{{item.jine}}%</div>

                <div class="je-scribe">
                  当前等级可领取
                </div>
                <!-- 领取 -->
                <img class="je-lq-btn" v-if="item.status == 2"
                  @click="lingquLijing(wrapItem,item.type)"
                  src="../../assets/geren/quanyi-lq-btn.png" />
                <!-- 已领取 -->
                <img class="je-lq-btn" v-else-if="item.status == 3"
                  src="../../assets/geren/quanyi-ylq-btn.png" />
                <!-- 暂不可领取 -->
                <img class="je-lq-btn" v-else-if="item.status == 1"
                  src="../../assets/geren/quanyi-bklq-btn.png" />
                <!-- 未开启 -->
                <img class="je-lq-btn" v-else
                  src="../../assets/geren/quanyi-wkq-btn.png" />
              </div>
            </div>
          </div>
        </div>
        <img class="ls-ico r" @click='lsArrClick(-1)' v-if="gradeInfo.info"
          v-show="wrapperLNum < gradeInfo.info.length - 1"
          src="../../assets/geren/v-qy-s-right.png" />
      </div>
    </div>
  </div>
</template>
<script>
import Tool from '@/common/Tool';
import Request from '@/common/Request';
import { mapMutations } from 'vuex';

export default{
  data(){
    return {
      wrapperStartX:0,
      wrapperLeft:0,
      wrapperLNum:0,
      touchX:'none',
      isWrapEffect:true,
      gradeInfo:{},
      subExp:0,
      processExp:'',
    }
  },
  mounted(){

  },
  beforeRouteEnter(to,from,next){
    next(vm => {
      vm.getUserGrateInfo(() => {
        vm.gradeInfoBeautify();
      });
    });
  },
  methods:{
    ...mapMutations(['popMsg']),
    getLijinEduShow(gradeInfo){
      let r = [
        {
          title:'晋级彩金',
          jine:gradeInfo.bonus,
          status:gradeInfo.status.bonus,
          type:'bonus',
        },
        {
          title:'周礼金',
          jine:gradeInfo.week_bonus,
          status:gradeInfo.status.week_bonus,
          type:'week_bonus',
        },
        {
          title:'月礼金',
          jine:gradeInfo.month_bonus,
          status:gradeInfo.status.month_bonus,
          type:'month_bonus',
        },{
          title:'额外充值优惠',
          jine:gradeInfo.recharge_promo,
          status:gradeInfo.status.recharge_promo,
          type:'recharge_promo',
        }
      ];
      return r;
    },
    getSelectedLevel(){
      let grade = 1;
      if(this.gradeInfo && this.gradeInfo.info && this.gradeInfo.info.length){
        grade = this.gradeInfo.info[this.wrapperLNum].grade;
      }
      return grade;
    },
    getNextLevelIco(isnow){
      let grade = 1;
      if(this.gradeInfo){
        let key = isnow ? 'grade' : 'nextGrade';
        let grade1 = this.gradeInfo[key];
        if(grade1){
          grade = grade1;
        }
      }
      return require(`../../assets/home/top/vip-level/vip-level-ico${grade}.png`)
    },
    quanyiListWtouchstart(ev){
      this.wrapperStartX = ev.changedTouches[0].clientX;
    },
    quanyiListWtouch(ev){
      let curr = ev.changedTouches[0].clientX;
      if(this.touchX != 'none'){
        this.wrapperLeft += (curr - this.touchX) / 2.5
      }
      this.touchX = curr
      this.isWrapEffect = false;
    },
    quanyiListWtouchend(ev){
      let x = ev.changedTouches[0].clientX;
      if(x - this.wrapperStartX > 0){
        //向左
        this.lsArrClick(1);
      }else if(x - this.wrapperStartX < 0){
        //向右
        this.lsArrClick(-1);
        this.setXbyNumber(this.wrapperLNum);
      }
      this.wrapperStartX = x;
      this.touchX = 'none';
      this.isWrapEffect = true;
    },
    lsArrClick(direction){
      if(direction > 0){
        this.wrapperLNum--
        if(this.wrapperLNum < 0) this.wrapperLNum = 0;
        this.setXbyNumber(this.wrapperLNum);
      }else if(direction < 0){
        this.wrapperLNum++
        let max = this.gradeInfo.info.length - 1;
        if(this.wrapperLNum > max){
          this.wrapperLNum = max
        }
        this.setXbyNumber(this.wrapperLNum);
      }
    },
    vipLevelBtnClick(i){
      this.wrapperLNum = i;
      this.setXbyNumber(this.wrapperLNum);
    },
    setXbyNumber(num){
      let containerWidth = Tool.remToPx(5.5);
      this.wrapperLeft = -num * containerWidth;
      if(num > 4){
        this.$refs.vipCeilWrapper.scrollTo((num - 5) * 79 + 100,0)
      }
      if(num < 5){
        this.$refs.vipCeilWrapper.scrollTo(0,0)
      }
    },
    getUserGrateInfo(callback){
      let localGradeInfo = Tool.getGradeInfo();
      if(localGradeInfo){
        this.gradeInfo = localGradeInfo;
        callback();
        return;
      }
      Request.post(`/user/grade_info`).then(res => {
        if(res.data && res.data.data){
          Tool.setGradeInfo(res.data.data)
          this.gradeInfo = res.data.data;
          callback();
        }
      }).catch(() => {
        callback();
        Request.userOutted(this)
      });
    },
    gradeInfoBeautify(){
      let numNextExp = Number(this.gradeInfo.nextExp);
      let numExp = Number(this.gradeInfo.exp);
      let subExp = numNextExp - numExp;
      if(isNaN(numNextExp) || isNaN(numExp) || subExp < 0){
        subExp = 0;
        this.processExp = 0;
      }else{
        this.processExp = `${numExp / numNextExp * 100}%`;
      }
      this.subExp = subExp.toFixed(3);      
    },
    lingjinbiBackUp(){
      Tool.remveGradeInfo()
      this.getUserGrateInfo(() => {
        this.gradeInfoBeautify();
      });
    },
    lingquLijing(bonusItem,bType){
      let p = {
        rule_id:bonusItem.id,
        type:bType
      };
      Request.post(`/activity/grade_apply`,p).then(res => {
        if(res.data && res.data.data){
          this.popMsg(res.data.msg);
        }
        this.lingjinbiBackUp()
      }).catch(error => {
        if(error.response && error.response.data && error.response.data.msg){
          this.popMsg(error.response.data.msg);
        }
        this.lingjinbiBackUp()
      });
    }
  }
}
</script>
<style scoped lang="stylus">
.rank-privilege
  width 100%
  height 100%
  padding-bottom .5rem
  overflow auto
  .q-o-wrapper
    width 5.5rem
    .ls-ico
      position absolute
      width .16rem
      top .57rem
      &.l
        left -.2rem
      &.r
        right -.2rem
  .wrapper-ov-hidden
    overflow hidden
  .wrapper-i-list
    &.effect
      transition transform .3s
  .quanyi-i-list
    width 5.5rem
    padding .1rem .2rem
    justify-content space-around
    flex-shrink 0
  .quanyi-item
    width 1rem
    height 1.32rem
    background-image url(../../assets/geren/vip-quan-bg.png)
    background-size 100% 100%
    flex-shrink 0
    &:last-child
      margin-right 0
      .quanyi-top-title
        background-image url(../../assets/geren/quanyi-top-title1.png)
    .quanyi-top-title
      width 1rem
      height .28rem
      text-align center
      font-size .12rem
      padding-top .04rem
      color #ffffff
      margin 0 auto
      background-image url(../../assets/geren/quanyi-top-title.png)
      background-size 100% 100%
    .qy-de-coin1
      height .36rem
      margin 0 auto
      display block
    .jine
      width 100%
      color #F0E848
      text-align center
      font-size .16rem
    .je-scribe
      width 100%
      font-size .1rem
      text-align center
      color #B9ADAD
    .je-lq-btn
      width .66rem
      margin .04rem auto 0 auto
      display block
  .vip-quanyi-wrapper
    width 5.5rem
    height auto
    margin 0 auto
    border-radius .1rem
  .vip-scribe-wrap
    width 100%
    height .22rem
    color #ffffff
    font-size .12rem
    padding-left .1rem
    align-items center
    margin-top .04rem
    span
      color red
  .vip-q-t-b-w
    width 5.5rem
    height .38rem
    justify-content center
    align-items center
    background-image url(../../assets/geren/vip-quanyi-t-bg.png)
    background-size 100% 100%
    background-repeat no-repeat
    .vip-ceil-wrapper
      width 4.52rem
      height 80%
      margin 0 .14rem
      flex-wrap nowrap
      overflow-x auto
      overflow-y hidden
      &::-webkit-scrollbar
        width 0
        height 0
      .vip-level-btn
        width .71rem
        height .3rem
        margin-right .08rem
        flex-shrink 0
        font-size .18rem
        font-weight bold
        justify-content center
        align-items center
        background-size 100% 100%
        color #FFF58F
        background-image url(../../assets/geren/v-quany-0.png)
        &.selected
          text-stroke 0
          -webkit-text-stroke 0
          color #870300
          background-image url(../../assets/geren/v-quany-1.png)
    .lr-ico
      width .24rem
  .decorate
    width 70%
    margin-left .5rem
  .vip-l-wrap
    width .5rem
    margin 0 .08rem 0 .48rem
    .vip-ico
      width 100%
      display block
    .vip-l-value
      top .11rem
      left .11rem
      text-shadow 0 0 4px #000000
      position absolute
      color #FFF345
  .vip-bar
    margin-top .09rem
    border-top 1px solid transparent
    align-items start
  .normal-vip
    width .6rem
  .process-bar-wrap
    flex-direction column
    align-items center
    margin .02rem .15rem 0 .15rem
  .process-bar
    width 3.2rem
    height .18rem
    border-radius .12rem
    background-image url(../../assets/geren/member-bg-process.png)
    background-size 100% 100%
    color #B94C23
    justify-content center
    align-items center
    position relative
    .p-vip-value
      color #A99678
      top -.12rem
      position absolute
    .pro-inner
      width 100%
      height 100%
      position absolute
      top 0
      left 0
      text-align center
      line-height .2rem
      overflow hidden
      .inner-bar
        width 3.2rem
        background-image url(../../assets/geren/member-fg-process.png)
        background-size 100% 100%
        border-radius .12rem
        height 100%
  .vip-ico-wrapper
    width .54rem
    height .28rem
    margin-top -.11rem
    img
      display block
      width 100%
</style>