<template>
  <BankInfo :isShow="isShowBankInfo" style="z-index:4"
    :bank="userInfo && userInfo.bank?userInfo.bank:{}"
    :aliplay="alipayInfo"  />

  <BindAlipay :isShow="isShowBindAlipay" style="z-index:4" 
    @bindSuccess="alipayBindSuccess"
    :aliyName="userInfo?userInfo.name:''" 
    :alipay="userInfo?userInfo.alipay:''" />

  <BindBankCard :isShow="isShowBindBank" style="z-index:4"
    @bindSuccess="accBindSuccess" />
  
  <BindDigitalWallet :isShow="isShowBindDigitalWallet" style="z-index:4"
    @bindSuccess="walletBindSuccess" />

  <WalletInfo :isShow="isShowWalletInfo" style="z-index:4"
    :wallets="userInfo.wallets && userInfo.wallets.length?userInfo.wallets:null" />
  
  <DuihuanConfirm :withdrawInfo="withdrawInfo" :show="confirmShow" 
    :dhAmount="dhAmount" @confirm="confirmSubmit" />
    
  <DuihuanRecord :show="isShowRecord" />

  <DuihuanSmsCheck :show="smsCheckShow" @smsConfirm="confirmSmsSubmit" />

  <PopWidget width="6rem" :isFull="1" :isShow="isShowPopWidget">
    <template v-slot:title>
      <img class="l-t-title" 
        src="../../assets/duihuan/duihuan-title.png" />
    </template>
    <template v-slot:content>
      <div class="main-content flex">
        <div class="menu-wrap">
          <div class="inner-back">
            <div class="menu-wrap-scroll">
              <div :key="menu.id" class="menu-btn" v-for="(menu,i) of menuItems"
                @click="menuClick(i)" :class="{select:menuSelectIndex==i}"
                v-show="menu.show" :data-id="menu.id">
                <div class="menu-text">
                  {{ menu.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-w-page">
          <DuihuanAmount :userInfo="userInfo" :wayId="currentMenuId"
            :withdrawInfo="withdrawInfo" :currMenuItem="currMenuItem"
            @showAlipayClick="showAlipayClick"
            @bandingAlipayClick="bandingAlipayClick" 
            @duihuanSubClick="duihuanSubClick"
            @showRecordClick="showRecordClick"
            @walletChage="walletChangeHandle" />
        </div>
      </div>
    </template>
  </PopWidget>
</template>
<script>
import PopWidget from '../common/PopWidget.vue'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import BankInfo from '../GerenZhongxin/BankInfo.vue'
import BindAlipay from '../GerenZhongxin/BindAlipay.vue'
import BindBankCard from '../GerenZhongxin/BindBankCard.vue'
import BindDigitalWallet from '../GerenZhongxin/BindDigitalWallet.vue'
import WalletInfo from '../GerenZhongxin/WalletInfo.vue'
import DuihuanAmount from './DuihuanAmount.vue'
import DuihuanConfirm from './DuihuanConfirm.vue'
import DuihuanRecord from './DuihuanRecord.vue'
import DuihuanSmsCheck from './DuihuanSmsCheck.vue'
import { mapMutations } from 'vuex'

export default{
  emits:['duihuanSuccess'],
  props:{
    show:Number,
  },
  data(){
    return{
      userInfo:{},
      menuItems:[
        {
          id:'way-0',
          name:'兑换到支付宝',
          show:0,
        },
        {
          id:'way-1',
          name:'兑换到银行卡',
          show:0,
        },
        {
          id:'way-3',
          name:'兑换到数字钱包',
          show:0,
        },
        {
          id:'way-2',
          name:'免提直充',
          show:0,
        }
      ],
      isShowPopWidget:0,
      menuSelectIndex:0,
      promoInfo:{},
      /*
      chargeout_setting:{
        out_lower_limit_alipay 支付宝最小提现值
        out_upper_limit_alipay 支付宝最大提现值
        out_lower_limit_bank 银行卡最小提现值
        out_upper_limit_bank 银行卡最大提现值
        out_lower_limit_wallet 数字钱包最小提现值
        out_upper_limit_wallet 数字钱包最大提现值
        out_lower_limit_w_recharge 直充最小提现值
        out_upper_limit_w_recharge 直充最大提现值
      }
      */
      withdrawInfo:{},
      dhAmount:0,
      isShowBankInfo:0,
      isShowBindAlipay:0,
      isShowBindBank:0,
      isShowWalletInfo:0,
      isShowBindDigitalWallet:0,
      confirmShow:0,
      alipayInfo:{},
      payWayAccount:'',
      seletWalletId:0,
      requesting:false,
      isShowRecord:0,
      currMenuItem:{},
      smsCheckShow:0,
    }
  },
  methods:{
    ...mapMutations(['showLoading','closeLoading','popMsg']),
    showRecordClick(){
      this.isShowRecord = Math.random();
    },
    tixianChongzhiSubmit(sms_code){
      if(this.requesting) return;
      let smsCheck = 1 * this.withdrawInfo.sms_check;
      if(smsCheck == 1 && !sms_code){
        this.smsCheckShow = Math.random();
        return;
      }
      this.showLoading();
      this.requesting = true;
      let p = {
        amount:this.dhAmount,
      }
      if(sms_code){
        p.code = sms_code;
      }
      Request.post('/user/w_recharge',p).then(res => {
        this.closeLoading();
        if(res.data.code == 0){
          this.getWithdrawInfo();
          this.$emit('duihuanSuccess')
          this.popMsg('申请提交成功')
        }else{
          this.popMsg(res.data.msg)
        }
        this.requesting = false;
        this.smsCheckShow = 0;
      }).catch((error) => {
        this.requesting = false;
        this.closeLoading();
        if(error.response && error.response.data && error.response.data.msg){
          if(error.response.data.code == 1000006){
            Request.userOutted(this);
          }else{
            this.popMsg(error.response.data.msg)
          }
        }
      })
    },
    confirmSmsSubmit(sms_code){
      this.confirmSubmit(sms_code);
    },
    confirmSubmit(sms_code){
      if(this.currentMenuId == 'way-2'){
        this.tixianChongzhiSubmit(sms_code);
        return;
      }

      if(this.requesting) return;
      let smsCheck = 1 * this.withdrawInfo.sms_check;
      if(smsCheck == 1 && !sms_code){
        this.smsCheckShow = Math.random();
        return;
      }
      /*
      如果用户选择的是数字钱包，传值            
        dic = @{@"amount":amount,
              @"withdraw_type":@"3",
              @"withdraw_account":address,
              @"wallet_id":uid
          }; 
          amount是提现的金额，withdraw_account是数字钱包列表里面对应的字段，
          和wallet_id的id

          如果支付宝和银行卡。
          dic = @{@"amount":amount,
                @"withdraw_type":self.withdraw_type,
                @"withdraw_account":self.yinhangkaTextField.text};
          withdraw_type的值，支付宝为2，银行卡为1，
          withdraw_account就是对应的银行卡账号，支付宝账号
          amount为提现金额
      */
      const p = {
        amount:this.dhAmount,
        //3:数字钱包 2:支付宝 1:银行卡
        withdraw_type:0,
        withdraw_account:this.payWayAccount,
      }
      if(sms_code){
        p.code = sms_code;
      }
      const wayId = this.currentMenuId;
      if(wayId == 'way-0'){
        p.withdraw_type = 2;
      }else if(wayId == 'way-1'){
        p.withdraw_type = 1;
      }else if(wayId == 'way-3'){
        p.withdraw_type = 3;
      }
      if(this.seletWalletId){
        p.wallet_id = this.seletWalletId;
      }
      this.showLoading();
      this.requesting = true;
      Request.post('/user/withdraw',p).then(res => {
        this.closeLoading();
        if(res.data.code == 0){
          this.getWithdrawInfo();
          this.$emit('duihuanSuccess')
          this.popMsg('申请提交成功')
        }else{
          this.popMsg(res.data.msg)
        }
        this.requesting = false;
        this.smsCheckShow = 0;
      }).catch((error) => {
        this.requesting = false;
        this.closeLoading();
        if(error.response && error.response.data &&
          error.response.data.msg){
          this.popMsg(error.response.data.msg)
        }
      })
    },
    duihuanSubClick(p){
      this.dhAmount = p.dhAmount;
      this.payWayAccount = p.account;
      this.confirmShow = Math.random()
    },
    walletBindSuccess(){
      this.updateUserinfo();
    },
    accBindSuccess(){
      this.updateUserinfo();
    },
    alipayBindSuccess(){
      this.updateUserinfo();
    },
    updateUserinfo(){
      Tool.removeLocalUserInfo();
      Request.get(`/user/info`).then(res => {
        if(res.data && res.data.data){
          Tool.setLocalUserinfo(res.data.data);
          this.userInfo = res.data.data;
        }
      });
    },
    bandingAlipayClick(wayId){
      if(wayId == 'way-0'){
        this.isShowBindAlipay = Math.random();
      }else if(wayId == 'way-1'){
        this.isShowBindBank = Math.random();
      }else if(wayId == 'way-3' || Tool.walletId.includes(wayId)){
        this.isShowBindDigitalWallet = Math.random();
      }
    },
    walletChangeHandle(wallet){
      this.seletWalletId = wallet.id;
    },
    showAlipayClick(wayId){
      if(wayId == 'way-0'){
        if(this.userInfo && this.userInfo.alipay){
          this.alipayInfo = {
            alipay:this.userInfo.alipay,
            name:this.userInfo.name
          };
          this.isShowBankInfo = Math.random()
        }
      }else if(wayId == 'way-1'){
        this.alipayInfo = null;
        this.isShowBankInfo = Math.random()
      }else if(wayId == 'way-3' || Tool.walletId.includes(wayId)){
        this.isShowWalletInfo = Math.random();
      }
    },
    getWithdrawInfo(callback){
      Request.post('/user/get_withdraw_info').then(res => {
        this.withdrawInfo = res.data.data;//sms_check
        if(callback) callback();
      }).catch(() => {
        if(callback) callback();
      })
    },
    getPromoInfo(){
      let localPromo = null // Tool.getPromLocalInfo();
      if(localPromo){
        this.promoInfo = localPromo;
        this.promoDataHandle();
        return;
      }
      Request.post('/promo/info').then(res => {
        if(res.data.data){
          this.promoInfo = res.data.data;
          Tool.setPromLocalInfo(res.data.data);
          this.promoDataHandle();
        }else{
          this.promoInfo = {}
        }
      })
    },
    promoDataHandle(){
      /*
      bank_status :1
      alipay_status：1
      wallet_status:1
      w_recharge_status:1
      顺序 支付宝， 银行卡，数字钱包，免提直充
      */
      if(this.promoInfo.alipay_status == 1){
        this.showMenuByid('way-0');
      }else{
        this.removeMenuByid('way-0');
      }
      if(this.promoInfo.bank_status == 1){
        this.showMenuByid('way-1');
      }else{
        this.removeMenuByid('way-1');
      }
      if(this.promoInfo.wallet_status == 1){
        this.showMenuByid('way-3');
      }else{
        this.removeMenuByid('way-3');
      }
      if(this.promoInfo.w_recharge_status == 1){
        this.showMenuByid('way-2');
      }else{
        this.removeMenuByid('way-2');
      }
    },
    showMenuByid(id){
      let index = -1;
      for(let i = 0,len = this.menuItems.length; i < len; i++){
        if(this.menuItems[i].id == id){
          index = i;
        }
      }
      if(index > -1){
        this.menuItems[index].show = 1;
      }
    },
    removeMenuByid(id){
      let index = -1;
      for(let i = 0,len = this.menuItems.length; i < len; i++){
        if(this.menuItems[i].id == id){
          index = i;
        }
      }
      if(index > -1){
        this.menuItems.splice(index,1);
      }
    },
    getWalletList(){
      const _this = this;
      return new Promise((resOut,rej) => {
        if(_this.walletThirds && _this.walletThirds.length) {
          resOut()
          return;
        }

        Request.post('/user/wallet_thirds').then(res => {
          const walletList = res.data.data?res.data.data:[];
          if(walletList.length){
            walletList.forEach(wallet => {
              wallet.show = 1;
              _this.menuItems.push(wallet);
            })
          }
          _this.walletThirds = walletList;
          resOut()
        }).catch(() => resOut())
      })
      
    },
    menuClick(i){
      this.currMenuItem = this.menuItems[i];
      // let cId = this.currMenuItem.id;
      // if(Tool.walletId.includes(cId)){
      //   if(this.userInfo.wallets && this.userInfo.wallets.length){
      //     this.seletWalletId = this.userInfo.wallets[0].id;
      //   }
      // }else{
      //   this.seletWalletId = 0;
      // }
      this.menuSelectIndex = i;
    }
  },
  computed:{
    currentMenuId(){
      if(this.menuItems.length && this.menuItems[this.menuSelectIndex]){
        return this.menuItems[this.menuSelectIndex].id;
      }
    },
  },
  watch:{
    show(n){
      if(n){
        this.isShowPopWidget = n;
        this.getPromoInfo();

        this.showLoading();
        this.getWithdrawInfo(() => this.closeLoading());
        this.userInfo = Tool.getLocalUserinfo();
      }
    }
  },
  components:{
    PopWidget,
    BankInfo,
    BindAlipay,
    DuihuanAmount,
    BindBankCard,
    BindDigitalWallet,
    WalletInfo,
    DuihuanConfirm,
    DuihuanRecord,
    DuihuanSmsCheck,
  }
}
</script>

<style scoped lang="stylus">
.main-content
  width 100%
  height auto
  .menu-wrap
    width 1.8rem
    height 2.28rem
    position relative
    z-index 1
    overflow auto
    &::-webkit-scrollbar
      width 0
    .inner-back
      width 1.23rem
      min-height 100%
      height auto
      padding-bottom .3rem
    .menu-wrap-scroll
      width 100%
      height auto
      padding-top .12rem
      .menu-btn
        width 100%
        height .38rem
        display block
        margin-bottom .06rem
        position relative
        background-image url(../../assets/recharge/recharge-menu.png)
        background-size 100% 100%
        &:active
          appearance none
          -moz-appearance none
          -webkit-appearance none
        .select-bg
          display none
        &.select
          background-image url(../../assets/recharge/recharge-menu1.png)
          .menu-text
            color #842404
        .menu-text
          width 100%
          height 100%
          text-align center
          line-height .38rem
          font-size .12rem
          position absolute
          z-index 1
          top 0
          left 0
  .m-w-page
    width 6.15rem      
    height 2.28rem
    margin-left -.05rem
    background-image url(../../assets/geren/geren-back.png)
    background-repeat no-repeat
    background-size 100% 100%
    overflow auto
</style>