<template>
<div class="login" ref="loginContainerEl" :data-h="wrapperHeight">
  <div class="lottie-wrapper" ref="loginLottieWrapper">
  </div>
  <div class="official-wrapper"
    :class="[browserBrand,`${isShuping?'shuping':''}`]">
    <div class="official" @click="officialClick">
    </div>
    <div class="official c" @click="customerClick">
    </div>
    <div class="official s" @click="choiceIcoClick">
      <div class="s-btn">
        选择<br>线路
      </div>
    </div>
  </div>
  <div class="func">
    <div class="btn sign-in" @click="annonymouseLogin"></div>
    <div class="btn sign-up" @click="accoutRegist"></div>
    <div class="btn" @click="accoutLogin"></div>
  </div>
  <Login :isAnonymousLogin="anonymousLogin" @forgetPwd="goForgetPwd"
    :isShow="isShowAccLogin" @goSignUp="goToSignup" />
  <Regist :isShow="isShowAccRegist" :invokSource="regiistType" />
  <ServerLineChoice :isShow="isShowLineChoice" />
</div>
</template>
<script>
import Login from '../components/Login.vue'
import Regist from '../components/Regist.vue'
import Tool from '../common/Tool.js'
import Request from '../common/Request.js'
import ServerLineChoice from '../components/ServerLineChoice.vue'
import LottieZipShow from '../common/LottieZipShow.js'

export default {
  data(){
    return {
      isShowAccLogin:0,
      isShowAccRegist:0,
      isShowLineChoice:0,
      anonymousLogin:0,
      prevAnonyLoginTime:0, //上次游客登录时间
      regiistType:0, //0注册  1绑定手机  2找回密码
      isShuping:0,
      browserBrand:'',
      wrapperHeight:'',
    }
  },
  mounted(){
    this.browserBrand = Tool.getMobileBrand();
    if(Tool.getApiToken()){
      this.$router.push({name:'main'})
    }else{
      Tool.quit();
    }
    
    let animateEle = this.$refs.loginLottieWrapper;
    LottieZipShow.showIco(
      `/lottie-web/login.zip`,
      'login-animate',
      animateEle,
      'widthHeightRatio',
      null);
    
    window.addEventListener('orientationchange',() => {
      this.changeShuping();
    });
    window.addEventListener('resize',() => {
      this.changeShuping();
    });
    this.changeShuping();

    this.wrapperHeight = 
      `${this.$refs.loginContainerEl.clientHeight}-${innerHeight}`;
  },
  methods:{
    changeShuping(){
      this.isShuping = innerHeight > innerWidth;
    },
    customerClick(){
      this.getCustomerUrl(url => {
        window.open(url)
      });
    },
    officialClick(){
      let url = globalConfig.officialWebsite;
      window.open(url)
    },
    getCustomerUrl(callback){
      let customer = Tool.getCusLink();
      if(customer){
        callback(customer);
      }else{
        Request.get('/pub/callCustomer').then(res => {
          if(res.data.code == 0){
            Tool.setCusLink(res.data.data);
            callback(res.data.data);
          }
        })
      }
    },
    accoutLogin(){
      this.isShowAccLogin = Math.random();
    },
    annonymouseLogin(){
      let now = new Date();
      let subTime = now - this.prevAnonyLoginTime
      if(subTime < 1000 * 5){
        return;
      }
      if(Tool.getApiToken()){
        this.$router.push({name:"main"})
        return;
      }
      this.prevAnonyLoginTime = now;
      this.anonymousLogin = Math.random();
    },
    accoutRegist(){
      this.regiistType = 0;
      this.isShowAccRegist = Math.random();
    },
    choiceIcoClick(){
      this.isShowLineChoice = Math.random();
    },
    goToSignup(){
      this.regiistType = 0;
      this.isShowAccRegist = Math.random();
    },
    goForgetPwd(){
      this.regiistType = 2;
      this.isShowAccRegist = Math.random();
    }
  },
  components:{
    Login,
    Regist,
    ServerLineChoice
  }
}
</script>

<style scoped lang="stylus">
.login
  width 100%
  height 100%
  .official-wrapper
    width .48rem
    user-select none
    position absolute
    right .1rem
    top .1rem
    &.shuping
      right .36rem
    &.mb2345browser,&.vivo
      right .48rem
  .official
    width .48rem
    height .7rem
    position relative
    background-image url('../assets/login/official-ico.png')
    background-size cover
    &.c
      background-image url('../assets/login/server-ico.png')
    &.s
      line-height .14rem
      height .48rem
      border-radius 100%
      background-color rgba(255,255,255,.7)
      background-image none
      display flex
      .s-btn
        margin auto
        width .35rem
        font-size .12rem
        font-weight bold
        text-align center
        color #000000
  .lottie-wrapper
    width 100%
    height 100%
    position absolute
    bottom 0
    left 50%
    transform translateX(-50%)
    user-select none
  .func
    width 100%
    height auto
    user-select none
    display flex
    position absolute
    justify-content center
    bottom .4rem
    .btn
      width 1.4rem
      height .47rem
      background-image url('../assets/login/login-btn.png')
      background-size 100% 100%
      transition transform .3s
      &:active
        transform scale(.9)
      &.sign-up
        background-image url('../assets/login/signup-btn.png')
        margin 0 .6rem
      &.sign-in
        background-image url('../assets/login/login-btn1.png')
</style>