<template>
  <div class="tuiguang-bg flex" :class="{show:localShow}" 
    @touchmove.stop="" @touchstart.stop="">
    <DirectMember :show="isShowDirMember" />
    <LingquRecord :show="isShowLingquJilu" />
    <div class="tg-big-bg" :class="{show:isShowBigBg}">
      <img class="big-bg-close" @click="isShowBigBg = 0"
          src="../../assets/tuiguang/tuiguang-close.png" alt="">
      <div class="big-content flex">
        <div class="qrcode-wrap">
          <img v-if="agentYeildInfo.qr_code" :src="agentYeildInfo.qr_code"/>
        </div>
        <div class="o-wrapper-des">
          <div class="home-url">
            官方网址：<span>{{agentYeildInfo.url}}</span>
          </div>
          <div class="home-u-des">
            如遇扫码访问失败的情况，请<br/>
            尝试使用“<span class="browser">浏览器</span>”或者‘<span class="er-code">二<br>
            维码扫APP</span>’重新扫码。
          </div>
        </div>
        <div class="flex">
          <img @click="shareWechatClick" class="share-wechat-btn ease-btn-effe" 
            src="../../assets/tuiguang/tuiguang-wechat-btn.png" alt="">
          <img @click="shareWechatClick" class="share-wechat-btn ease-btn-effe" 
            src="../../assets/tuiguang/tuiguang-pyq-btn.png" alt="">
        </div>
      </div>
    </div>
    <div class="cont-container" :class="{show:beforeShow}">
      <img class="title" src="../../assets/tuiguang/tuiguang-h-title.png" alt="">
      <img class="close" src="../../assets/tuiguang/tuiguang-close.png" alt=""
        @click="tuiguangCloseClick">
      <div class="head-w flex">
        <div v-for="(hItem,hIndex) of headList"
          @click="headClick(hIndex)" 
          :class="{select:headIndex == hIndex}">
          {{ hItem.title }}
        </div>
      </div>
      <div class="my-tuiguang flex" v-if="headIndex == 0">
        <div>
          <div style="width:2.47rem;font-size:.12rem;color:#4C2418;">
            产生的返利请在3个月内领取，逾期未领取系统将自动清除
          </div>
          <div class="flex info-wrap">
            <div class="fanli-info">
              <div>
                昨日总返利：
                <span>
                  {{agentYeildInfo.yes_total?agentYeildInfo.yes_total:0}}
                </span>
              </div>
              <div>
                昨日直属会员返利：
                <span>
                  {{agentYeildInfo.yes_direct?agentYeildInfo.yes_direct:0}}
                </span>
              </div>
              <div>
                昨日其他返利：
                <span>
                  {{agentYeildInfo.yes_indirect?agentYeildInfo.yes_indirect:0}}
                </span>
              </div>
            </div>
            <div class="member-info">
              <div>
                直属会员：<span>
                  {{agentYeildInfo.sub_count?agentYeildInfo.sub_count:0}}人
                </span>
              </div>
              <div>
                其他会员：<span>
                  {{agentYeildInfo.indirect_sub_count?agentYeildInfo.indirect_sub_count:0}}人
                </span>
              </div>
            </div>
          </div>
          <div class="history">
            历史总奖励：<span>{{agentYeildInfo.history?agentYeildInfo.history:0}}</span>
            &nbsp;&nbsp;
            可领取奖励：<span>{{agentYeildInfo.apply?agentYeildInfo.apply:0}}</span>
          </div>
        </div>
        <div class="qrcode-wrapper flex">
          <img class="border-line"
            src="../../assets/tuiguang/tuiguang-border-line.png" />
          <div class="qrcode-d-title">点击图片预览大图</div>
          <div class="qrcode" @click="isShowBigBg=1">
            <img v-if="agentYeildInfo.qr_code" :src="agentYeildInfo.qr_code"/>
          </div>
          <input class="promo-input" ref="promoInput" readonly v-model="shareLink" />
          <div @click="copyBtnClick" class="copy-btn ease-btn-effe flex">
            复制链接
          </div>
        </div>
        <img class="liji-get-bonus ease-btn-effe" v-if="agentYeildInfo.apply"
            src="../../assets/tuiguang/liji-get-bonusbtn.png"
            @click="linquBonusClick">
        <img class="liji-get-bonus" v-else
            src="../../assets/tuiguang/liji-get-bonus.png">
        <img @click="gotoLinquRecord"
          class="go-btn" src="../../assets/tuiguang/gobtn-get-record-btn.png" />
        <img  @click="gotoDirMember"
          class="go-btn d-member" src="../../assets/tuiguang/gobtn-dir-member.png" />
      </div>
      <div class="tuiguang-j-cheng" v-if="headIndex == 1">
        <img class="pic" src="../../assets/tuiguang/tuiguang-des-txt.png" />
      </div>
      <div class="profit-list-wrapper" v-if="headIndex == 2">
        <div class="p-l-row head flex">
          <div>排名</div>
          <div>昵称</div>
          <div>获利金额</div>
        </div>
        <div class="p-l-wscroller">          
          <div class="p-l-row row flex" v-for="(agent,i) of agentYieldList">
            <div class="num-ico flex">
              <img v-if="i == 0" src="../../assets/tuiguang/list-ico1.png" />
              <img v-else-if="i == 1" src="../../assets/tuiguang/list-ico2.png" />
              <img v-else-if="i == 2" src="../../assets/tuiguang/list-ico3.png" />
              <img v-else src="../../assets/tuiguang/list-ico4.png" />
              <div class="num-wrap" v-if="i > 2">
                {{ i + 1 }}
              </div>
            </div>
            <div>{{ agent.nickname }}</div>
            <div class="ceil-amount">{{agent.amount}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import Request from '../../common/Request.js'
import Tool from '../../common/Tool.js'
import DirectMember from './DirectMember.vue'
import LingquRecord from './LingquRecord.vue'

export default{
  props:{
    show:Number,
    tabcode:String|Number,
  },
  data(){
    return{
      localShow:0,
      beforeShow:0,
      isShowBigBg:0,
      headList:[
        {title:'我的推广'},
        {title:'推广教程'},
        {title:'返利榜单'}
      ],
      headIndex:0,
      agentYeildInfo:{},
      agentYieldList:[],
      isShowLingquJilu:0,
      isShowDirMember:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg','alert']),
    gotoLinquRecord(){
      this.isShowLingquJilu = Math.random();
    },
    gotoDirMember(){
      this.isShowDirMember = Math.random();
    },
    linquBonusClick(){
      Request.post('/agent/get_yield').then(res => {
        if(res.data.code == 1){
          this.popMsg(res.data.msg);
        }
      }).catch(error => {
        this.popMsg(error.response.data.msg);
      })
    },
    copyBtnClick(){
      Tool.copy(this.$refs.promoInput)
      this.popMsg('复制成功!')
    },
    shareWechatClick(){
      Tool.copy(this.$refs.promoInput)
      this.alert('复制成功，如微信未能启动，请手动打开微信。')
      setTimeout(() => {
        Tool.openWechat()
      },200)
    },
    getAgentInfo(){
      let cach = null // Tool.getAgentYieldInfo();
      if(cach){
        this.agentYeildInfo = cach;
        return;
      }
      Request.post('/agent/yield_info').then(res => {
        this.agentYeildInfo = res.data.data;
        Tool.setAgentYieldInfo(res.data.data);
      })
    },
    headClick(index){
      this.headIndex = index;
      if(index == 2){
        this.getAgentYieldList()
      }
    },
    getAgentYieldList(){
      let p = {page:1,}
      Request.post('/agent/yield_list',p).then(res => {
        if(res.data && res.data.data && res.data.data.data){
          this.agentYieldList = res.data.data.data;
        }
      })
    },
    tuiguangCloseClick(){
      this.beforeShow = 0;
      this.localShow = 0
    }
  },
  watch:{
    tabcode(n){
      if(n == 7){
        this.headClick(2)
      }else{
        this.headClick(0)
      }
    },
    show(n){
      if(n){
        this.localShow = n;
        setTimeout(() => {
          this.beforeShow = n
        }),
        this.getAgentInfo();
      }
    }
  },
  computed:{
    shareLink(){
      return this.agentYeildInfo.promo_url?
        this.agentYeildInfo.promo_url:this.agentYeildInfo.url;
    }
  },
  components:{
    DirectMember,
    LingquRecord
  }
}
</script>
<style scoped lang="stylus">
.tuiguang-bg
  width 100%
  height 100%
  top 0
  left 0
  position absolute
  background-color rgba(0,0,0,.25)
  z-index 3
  display none
  .tg-big-bg
    position absolute
    z-index 2
    top 0
    left 0
    width 100%
    height 100%
    background-image url(../../assets/tuiguang/tuiguang-big-bg.png)
    background-size 100% 100%
    display none
    &.show
      display block
    .big-bg-close
      width .38rem
      position absolute
      right .52rem
      top .12rem
    .big-content
      position absolute
      bottom .64rem
      right .6rem
      width 4.6rem
      height .93rem
      padding-left .1rem
      align-items center
      
      .share-wechat-btn
        width .5rem
        margin 0 0 0 .1rem
      .o-wrapper-des
        margin-left .1rem
      .home-url
        color #DEAD32
      .home-u-des
        margin-top .05rem
        color #ffffff
        .browser
          color #1EA7EA
        .er-code
          color #00CD44
      .qrcode-wrap
        padding .04rem
        border-radius .04rem
        background #FFCD95
        img
          width .66rem
  &.show
    display flex
  .cont-container
    width 4.4rem
    height 2.4rem
    margin auto
    background-image url(../../assets/tuiguang/tuiguang-background.png)
    background-size 100% 100%
    position relative
    .title
      width 3.44rem
      position absolute
      top -.4rem
      left 50%
      transform translateX(-50%)
    .close
      width .38rem
      position absolute
      top 0
      right -.4rem
    .head-w
      width 4rem
      height .28rem
      border-radius .3rem
      background-color #4B2D37
      top .37rem
      position absolute
      left .2rem
      justify-content space-around
      align-items center
      & > div
        width 25%
        height 78%
        font-size .12rem
        display flex
        justify-content center
        align-items center
        border-radius .3rem
        color #FFDBC0
        &.select
          background-image linear-gradient(#FFFEE6,#F9F6DE,#CBB79F,#9F7B62)
          color #512914
          border 1px solid #FDFCC4
    .tuiguang-j-cheng
      width 3.46rem
      height 1.3rem
      position absolute
      top .69rem
      left .35rem
      .pic
        width 3.7rem
    .profit-list-wrapper
      width 3.46rem
      height 1.37rem
      padding-bottom .02rem
      position absolute
      top .68rem
      left .47rem
      padding-top .06rem
      .p-l-wscroller
        width 100%
        height 1.14rem
        overflow-y auto
      .p-l-row
        width 100%
        height .26rem
        align-items center
        justify-content flex-start
        font-size .12rem
        & > div
          width 33.3%
          text-align center
        .ceil-amount
          color #FAD88E
        .num-ico
          justify-content center
          align-items center
          position relative
          img
            width .16rem
          .num-wrap
            text-align center
            width 100%
            position absolute
        &.head
          color #E7D1C3
          background-color #482E37
        &.row
          color #FFF2FD
          &:nth-child(even)
            background #946D82
          &:nth-child(odd)
            background #724C61
    .my-tuiguang
      padding-left .12rem
      position absolute
      top .68rem
      left .29rem
      .qrcode-wrapper
        position relative
        padding-left .08rem
        margin-left .08rem
        font-size .12rem
        flex-direction column
        align-items center
        .border-line
          display block
          height 1.28rem
          position absolute
          top 0
          left 0
        .qrcode
          width .86rem
          height auto
          padding .06rem 0
          margin .05rem 0
          border-radius .04rem
          background #8b6572
          img
            width .7rem
            display block
            margin auto
        .promo-input
          width .74rem
          height .22rem
          background none
          position absolute
          bottom .07rem
          left .2rem
          z-index 1
        .copy-btn
          width .74rem
          height .22rem
          justify-content center
          align-items center
          position absolute
          bottom .07rem
          left .2rem
          color #573333
          background #F5D7CD
          border-radius .12rem
          z-index 1
        .qrcode-d-title
          color #622913
      .history
        margin-top .02rem
        text-align center
        width 2.48rem
        color #572D2F
        background-image url(../../assets/tuiguang/score-background.png)
        background-size 100% 100%
        font-size .12rem
        line-height .2rem
        span
          color #ff0000
      .liji-get-bonus
        width 1.1rem
        position absolute
        bottom -.36rem
        left .8rem
      .go-btn
        width .6rem
        position absolute
        bottom -.36rem
        right 1.04rem
        &.d-member
          right .4rem
      .info-wrap
        height .8rem
        color #FCD8DA
        font-size .12rem
        &>div
          padding .05rem 0 0 .05rem
          &>div
            margin-bottom .1rem
      .fanli-info
        width 1.4rem
        height 100%
        border-radius .04rem
        overflow hidden
        background-color #8B6572
        span
          color #F3C012
      .member-info
        width 1rem
        height 100%
        margin-left .08rem
        border-radius .04rem
        overflow hidden
        background-color #8B6572
        span
          color #05D202
</style>